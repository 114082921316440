const SVGHero = () => (
  <svg
    width="481"
    height="376"
    viewBox="0 0 481 376"
    fill="none"
    style={{ width: '100%' }}
    xmlns="http://www.w3.org/2000/svg"
    role="img"
  >
    <title>Graphic design of stairs, bouncing balls and arrow</title>
    <path
      d="M416.088 103.785L473.894 78.8174L318.705 40.5501L260.899 65.5175L416.088 103.785Z"
      fill="#202DAC"
    />
    <path
      d="M417.299 144.044L416.203 103.806L260.899 65.5173L261.995 105.755L417.299 144.044Z"
      fill="url(#paint0_linear_1401_10982)"
    />
    <path
      d="M302.918 234.198L360.724 209.23L205.535 170.963L147.729 195.931L302.918 234.198Z"
      fill="#939CEB"
    />
    <path
      d="M304.131 274.458L303.035 234.219L147.731 195.93L148.827 236.168L304.131 274.458Z"
      fill="url(#paint1_linear_1401_10982)"
    />
    <path
      d="M359.524 168.97L417.33 144.003L262.14 105.736L204.334 130.703L359.524 168.97Z"
      fill="#939CEB"
    />
    <path
      d="M360.734 209.23L359.639 168.992L204.335 130.703L205.43 170.941L360.734 209.23Z"
      fill="url(#paint2_linear_1401_10982)"
    />
    <path
      d="M246.354 299.384L304.16 274.417L148.97 236.15L91.1645 261.117L246.354 299.384Z"
      fill="#939CEB"
    />
    <path
      d="M247.564 339.644L246.469 299.406L91.1648 261.117L92.2601 301.355L247.564 339.644Z"
      fill="url(#paint3_linear_1401_10982)"
    />
    <ellipse
      rx="14.9199"
      ry="8.33912"
      transform="matrix(-0.99945 -0.033177 -0.033177 0.99945 208.562 185.899)"
      fill="url(#paint4_linear_1401_10982)"
    />
    <ellipse
      rx="8.2845"
      ry="4.61239"
      transform="matrix(-0.997203 -0.0747452 -0.0747452 0.997203 268.634 115.377)"
      fill="url(#paint5_linear_1401_10982)"
    />
    <circle
      r="26.3834"
      transform="matrix(0.854504 0.519445 0.519445 -0.854504 207.363 147.24)"
      fill="url(#paint6_radial_1401_10982)"
    />
    <circle
      r="26.3834"
      transform="matrix(0.854504 0.519445 0.519445 -0.854504 112.966 300.38)"
      fill="url(#paint7_radial_1401_10982)"
    />
    <circle
      r="14.7063"
      transform="matrix(-0.814846 0.579677 0.579677 0.814846 264.405 61.4911)"
      fill="url(#paint8_linear_1401_10982)"
    />
    <rect
      width="56.401"
      height="49.2267"
      transform="matrix(0.932881 0.360186 -0.933507 0.358559 47.6641 332.811)"
      fill="#FC5C03"
    />
    <path d="M100.153 333.563L15.4034 328.408L111.63 365.561L100.153 333.563Z" fill="#FC5C03" />
    <defs>
      <linearGradient
        id="paint0_linear_1401_10982"
        x1="335.746"
        y1="-18.3849"
        x2="340.225"
        y2="146.142"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.614583" stopColor="#061392" />
        <stop offset="0.911458" stopColor="#041578" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1401_10982"
        x1="222.578"
        y1="112.028"
        x2="227.057"
        y2="276.556"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.614583" stopColor="#061392" />
        <stop offset="0.911458" stopColor="#041578" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1401_10982"
        x1="279.182"
        y1="46.8007"
        x2="283.66"
        y2="211.328"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.614583" stopColor="#061392" />
        <stop offset="0.911458" stopColor="#041578" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_1401_10982"
        x1="166.012"
        y1="177.215"
        x2="170.491"
        y2="341.742"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.614583" stopColor="#061392" />
        <stop offset="0.911458" stopColor="#041578" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_1401_10982"
        x1="59.3481"
        y1="8.33912"
        x2="-3.53742e-06"
        y2="8.33912"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.777696" stopColor="#061392" />
        <stop offset="1" stopColor="#041578" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_1401_10982"
        x1="32.9539"
        y1="4.61239"
        x2="-1.9642e-06"
        y2="4.61239"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.777696" stopColor="#061392" />
        <stop offset="1" stopColor="#041578" />
      </linearGradient>
      <radialGradient
        id="paint6_radial_1401_10982"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(15.7754 30.2157) rotate(-8.08243) scale(30.8415 30.2403)"
      >
        <stop stopColor="#FBCFB7" />
        <stop offset="1" stopColor="#FF5B00" />
      </radialGradient>
      <radialGradient
        id="paint7_radial_1401_10982"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(15.7754 30.2157) rotate(-8.08243) scale(30.8415 30.2403)"
      >
        <stop stopColor="#FBCFB7" />
        <stop offset="1" stopColor="#FF5B00" />
      </radialGradient>
      <linearGradient
        id="paint8_linear_1401_10982"
        x1="38.2262"
        y1="-13.0493"
        x2="-11.3217"
        y2="26.1761"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFCA39" />
        <stop offset="1" stopColor="#FC6B1A" />
      </linearGradient>
    </defs>
  </svg>
);

// eslint-disable-next-line import/no-default-export
export default SVGHero;
