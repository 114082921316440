import styled from 'styled-components';

import { getDimension } from 'styled/helpers/getDimension';

/** The header for the Timeline, manually offset */
export const Header = styled.h2`
  margin-bottom: 150px;

  ${({ theme }) => theme.breakpoints.down('lg')} {
    margin-bottom: ${getDimension('size10')};
  }
`;
