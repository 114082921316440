import styled from 'styled-components';

import { getDimension } from 'styled/helpers/getDimension';

/**
 * The line that comes off the nodes on our Timeline component. \
 * It's positioning is changed based both upon whether or not its node is `highlighted` and upon it's index.
 */
export const Pointer = styled.div<{
  highlighted?: boolean;
  index: number;
}>`
  background-color: ${({ theme }) => theme.colors.orange};
  width: 2px;
  ${({ index, highlighted, theme }) => {
    const nodeMargin = highlighted
      ? getDimension('size6')({ theme })
      : getDimension('size5')({ theme });
    const leftMargin = highlighted ? '7px' : '5px';
    const nodeDirection = index % 2 ? 'bottom' : 'top';
    const height = highlighted ? '18px' : '32px';

    return {
      [`margin-${nodeDirection}`]: `${nodeMargin}`,
      'margin-left': leftMargin,
      height,
    };
  }}
`;
