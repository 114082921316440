const SVGAddressLocation = () => (
  <svg
    width="496"
    height="306"
    viewBox="0 0 496 306"
    fill="none"
    style={{ width: '100%' }}
    xmlns="http://www.w3.org/2000/svg"
    role="img"
  >
    <title>Graphic design of location icons</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M113.73 105.669C89.5592 81.4982 89.5592 42.3095 113.73 18.1386C137.901 -6.03222 177.09 -6.03222 201.261 18.1386C225.431 42.3095 225.431 81.4982 201.261 105.669C177.09 129.84 137.901 129.84 113.73 105.669ZM157.749 100.451C136.46 100.451 119.202 83.1926 119.202 61.9037C119.202 40.6148 136.46 23.3567 157.749 23.3567C179.038 23.3567 196.296 40.6148 196.296 61.9037C196.296 83.1926 179.038 100.451 157.749 100.451Z"
      fill="white"
    />
    <rect
      width="268.704"
      height="102.351"
      transform="matrix(-1 0 0 1 269.271 140.174)"
      fill="url(#paint0_linear_1809_13054)"
    />
    <ellipse
      rx="134.352"
      ry="27.3298"
      transform="matrix(-1 0 0 1 134.92 140.01)"
      fill="url(#paint1_linear_1809_13054)"
    />
    <ellipse
      rx="134.352"
      ry="27.3298"
      transform="matrix(-1 0 0 1 134.92 241.115)"
      fill="url(#paint2_linear_1809_13054)"
    />
    <ellipse
      opacity="0.1"
      cx="187.95"
      cy="150.441"
      rx="38.697"
      ry="5.65894"
      transform="rotate(-180 187.95 150.441)"
      fill="#3E4DDB"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M202.655 104.229C202.277 104.633 201.892 105.032 201.501 105.427L158.13 150.441L112.407 104.343L112.442 104.343C89.5672 80.0855 89.9964 41.8723 113.73 18.1386C137.901 -6.03222 177.09 -6.03222 201.261 18.1386C224.904 41.7817 225.42 79.7938 202.809 104.064L202.814 104.064L202.655 104.229ZM119.107 61.951C119.107 83.266 136.387 100.545 157.702 100.545C179.017 100.545 196.296 83.266 196.296 61.951C196.296 40.636 179.017 23.3567 157.702 23.3567C136.387 23.3567 119.107 40.636 119.107 61.951Z"
      fill="url(#paint3_radial_1809_13054)"
    />
    <rect
      width="268.704"
      height="72.5685"
      transform="matrix(-1 0 0 1 495.346 205.249)"
      fill="url(#paint4_linear_1809_13054)"
    />
    <ellipse
      rx="134.352"
      ry="27.3298"
      transform="matrix(-1 0 0 1 361 205.248)"
      fill="url(#paint5_linear_1809_13054)"
    />
    <ellipse
      rx="134.352"
      ry="27.3298"
      transform="matrix(-1 0 0 1 361 277.818)"
      fill="url(#paint6_linear_1809_13054)"
    />
    <g style={{ mixBlendMode: 'multiply' }} opacity="0.1">
      <ellipse
        rx="46.5724"
        ry="8.05284"
        transform="matrix(-1 0 0 1 397.783 211.625)"
        fill="#B04002"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M301.083 150.331C267.992 117.241 267.992 63.5902 301.083 30.4995C334.173 -2.59113 387.824 -2.59113 420.914 30.4995C454.005 63.5902 454.005 117.241 420.914 150.331C387.824 183.422 334.173 183.422 301.083 150.331ZM361.346 143.187C332.201 143.187 308.574 119.56 308.574 90.4153C308.574 61.2701 332.201 37.6433 361.346 37.6433C390.491 37.6433 414.118 61.2701 414.118 90.4153C414.118 119.56 390.491 143.187 361.346 143.187Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M299.32 148.516C268.003 115.307 268.59 62.9916 301.083 30.4995C334.173 -2.59114 387.824 -2.59114 420.914 30.4995C453.282 62.8675 453.989 114.907 423.035 148.135L423.041 148.135L422.823 148.361C422.306 148.912 421.779 149.459 421.244 150L361.868 211.626L299.271 148.516L299.32 148.516Z"
      fill="url(#paint7_radial_1809_13054)"
    />
    <circle r="66.0505" transform="matrix(-1 0 0 1 361.297 90.469)" fill="white" />
    <ellipse
      rx="66.0505"
      ry="66.0512"
      transform="matrix(-1 0 0 1 361.297 90.421)"
      fill="url(#paint8_linear_1809_13054)"
    />
    <ellipse
      rx="50.915"
      ry="50.9156"
      transform="matrix(-1 0 0 1 361.298 90.4214)"
      fill="url(#paint9_linear_1809_13054)"
    />
    <ellipse
      rx="36.4868"
      ry="36.4872"
      transform="matrix(-1 0 0 1 361.296 90.421)"
      fill="url(#paint10_linear_1809_13054)"
    />
    <circle
      r="50.2005"
      transform="matrix(-1 0 0 1 157.495 61.9511)"
      fill="url(#paint11_linear_1809_13054)"
    />
    <circle
      r="38.697"
      transform="matrix(-1 0 0 1 157.494 61.9513)"
      fill="url(#paint12_linear_1809_13054)"
    />
    <ellipse
      rx="27.7311"
      ry="27.7311"
      transform="matrix(-1 0 0 1 157.493 61.9511)"
      fill="url(#paint13_linear_1809_13054)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1809_13054"
        x1="349.223"
        y1="-45.4096"
        x2="210.802"
        y2="242.281"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FEBE9A" />
        <stop offset="1" stopColor="#FC6B1A" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1809_13054"
        x1="64.7606"
        y1="24.0076"
        x2="204.959"
        y2="24.8893"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A9B0EF" />
        <stop offset="1" stopColor="#D4D7F7" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1809_13054"
        x1="349.223"
        y1="-24.2506"
        x2="303.617"
        y2="153.237"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FEBE9A" />
        <stop offset="1" stopColor="#FC6B1A" />
      </linearGradient>
      <radialGradient
        id="paint3_radial_1809_13054"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(157.495 61.9039) rotate(90) scale(61.8934 61.8933)"
      >
        <stop offset="0.494792" stopColor="#FD7D35" stopOpacity="0" />
        <stop offset="1" stopColor="#FD7D35" />
      </radialGradient>
      <linearGradient
        id="paint4_linear_1809_13054"
        x1="54.8408"
        y1="72.5685"
        x2="126.757"
        y2="-23.8499"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#808BF3" />
        <stop offset="1" stopColor="#A3ABFB" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_1809_13054"
        x1="79.5769"
        y1="54.6597"
        x2="155.593"
        y2="-46.8213"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF823C" />
        <stop offset="1" stopColor="#FEC2A0" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_1809_13054"
        x1="54.8408"
        y1="54.6596"
        x2="103.106"
        y2="-31.2512"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#808BF3" />
        <stop offset="1" stopColor="#A3ABFB" />
      </linearGradient>
      <radialGradient
        id="paint7_radial_1809_13054"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(360.998 90.4154) rotate(90) scale(84.7339 84.7341)"
      >
        <stop offset="0.494792" stopColor="#7E88E7" stopOpacity="0" />
        <stop offset="1" stopColor="#7E88E7" />
      </radialGradient>
      <linearGradient
        id="paint8_linear_1809_13054"
        x1="66.0504"
        y1="117.482"
        x2="66.0504"
        y2="9.06701"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#BFC4F3" />
        <stop offset="1" stopColor="#BFC4F3" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_1809_13054"
        x1="50.915"
        y1="0"
        x2="50.915"
        y2="101.831"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3E4DDB" />
        <stop offset="1" stopColor="white" stopOpacity="0.58" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_1809_13054"
        x1="36.4868"
        y1="-7.71185"
        x2="36.4868"
        y2="72.9744"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#202DAC" />
        <stop offset="0.612851" stopColor="#3847D2" stopOpacity="0.188608" />
        <stop offset="1" stopColor="#3E4DDB" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint11_linear_1809_13054"
        x1="50.2004"
        y1="89.2893"
        x2="50.2004"
        y2="6.89113"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FCB48D" />
        <stop offset="1" stopColor="#FFE3D3" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint12_linear_1809_13054"
        x1="38.697"
        y1="0"
        x2="38.697"
        y2="77.3941"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FC6B1A" />
        <stop offset="1" stopColor="#FFE3D3" />
      </linearGradient>
      <linearGradient
        id="paint13_linear_1809_13054"
        x1="27.7311"
        y1="0"
        x2="27.7311"
        y2="55.4623"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FC6B1A" />
        <stop offset="1" stopColor="#FC6B1A" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);

// eslint-disable-next-line import/no-default-export
export default SVGAddressLocation;
