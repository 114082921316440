import { useContentfulLocale } from 'hooks/useContentfulLocale';
import { useGatsbyImage } from 'hooks/useGatsbyImage';

import { Layout } from 'components/Layout';
import { Seo } from 'components/Seo';
import { Container, HeroContainer, Section, SectionImage } from 'components/Container';

import { ExternalLink, GatsbyLink } from 'components/Links';
import { Timeline } from 'components/Timeline';

import Location from 'images/about-us/addressLocation';
import HeroImage from 'images/about-us/hero';
import Map from 'images/about-us/map';
import Cone from 'images/about-us/cone';

import type { FC } from 'react';

export const AboutUs: FC = () => {
  const { translations } = useContentfulLocale();

  return (
    <Layout hideFooterCta>
      <Seo />

      <HeroContainer background="cream" skipColoration>
        <Section>
          <h1>We build smart tools to make you a smarter investor</h1>
          <p>
            We show what brokers don’t – the full picture of your investments no matter where you
            are on your financial journey.
          </p>
        </Section>

        <SectionImage image={<HeroImage />} boxShadow={false} />
      </HeroContainer>
      <Container>
        <SectionImage image={<Map />} boxShadow={false} />
        <Section>
          <h2>Trusted by more than {translations.freeUsers} investors globally</h2>
          <p>
            Designed for all investors. Our portfolio tracker helps first-timers, experts and
            enterprises in 150+ countries to become smarter investors.
          </p>
        </Section>
      </Container>
      <Timeline />

      <Container>
        <Section>
          <h5>People of Sharesight</h5>
          <h2>We are a savvy team of investors, parents, frisbee champions and plant owners</h2>
          <p>
            Located in Wellington and Sydney, our team hails from all corners of the world. We
            believe that a diverse team is integral in creating products for investors, no matter
            their background.
          </p>
          <br />
          <GatsbyLink asButton to="team" appearance="secondary" showArrowIcon>
            Our executive team
          </GatsbyLink>
        </Section>
        <SectionImage
          firstOnMobile
          image={useGatsbyImage({
            name: 'shared-page-assets/globe',
            alt: 'Graphic illustration of hands hugging world map',
          })}
          boxShadow={false}
        />
      </Container>
      <Container>
        <SectionImage image={<Location />} boxShadow={false} />
        <Section>
          <h4>Our offices</h4>
          <br />
          <h4>Sydney</h4>
          <p>Level 4, 241 Commonwealth Street, Surry Hills, 2010, Australia</p>
          <br />
          <h4>Wellington</h4>
          <p>
            6 Market Lane, Wellington Central, Wellington, <br />
            6011, New Zealand
          </p>
        </Section>
      </Container>
      <Container>
        <Section>
          <h2>Careers at Sharesight</h2>
          <p>Want to help build the future of investment?</p>
          <br />
          <ExternalLink
            asButton
            to="https://www.linkedin.com/company/sharesight-limited/jobs/"
            showArrowIcon
            appearance="secondary"
          >
            Check out job listings
          </ExternalLink>
        </Section>
        <SectionImage image={<Cone />} boxShadow={false} />
      </Container>
    </Layout>
  );
};

export default AboutUs;
