const SVGCone = () => (
  <svg
    width="506"
    height="313"
    viewBox="0 0 506 313"
    fill="none"
    style={{ width: '100%' }}
    xmlns="http://www.w3.org/2000/svg"
    role="img"
  >
    <title>Graphic design of cone and circles</title>
    <path
      d="M5.78326 230.129L482.719 221.2L397.061 298.673L5.78326 230.129Z"
      fill="url(#paint0_linear_1401_11320)"
    />
    <circle
      cx="469.159"
      cy="129.817"
      r="16.886"
      transform="rotate(152.877 469.159 129.817)"
      fill="url(#paint1_linear_1401_11320)"
    />
    <path d="M368.182 241.97L6.26247 230.045L299.551 20.0141L368.182 241.97Z" fill="#FFC21A" />
    <path
      d="M368.279 241.898L6.35969 229.972L299.648 19.9416L368.279 241.898Z"
      fill="url(#paint2_linear_1401_11320)"
    />
    <ellipse
      rx="44.8046"
      ry="116.155"
      transform="matrix(0.955566 -0.294776 -0.294776 -0.955566 335.262 130.351)"
      fill="url(#paint3_linear_1401_11320)"
    />
    <circle
      cx="379.126"
      cy="54.5354"
      r="37.6571"
      transform="rotate(148.705 379.126 54.5354)"
      fill="url(#paint4_linear_1401_11320)"
    />
    <circle
      cx="464.508"
      cy="89.6132"
      r="29.9945"
      transform="rotate(152.877 464.508 89.6132)"
      fill="url(#paint5_linear_1401_11320)"
    />
    <circle
      cx="354.514"
      cy="155.02"
      r="27.4491"
      transform="rotate(152.877 354.514 155.02)"
      fill="url(#paint6_linear_1401_11320)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1401_11320"
        x1="285.114"
        y1="221.2"
        x2="418.105"
        y2="266.739"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D4D7F7" />
        <stop offset="1" stopColor="#D4D7F7" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1401_11320"
        x1="479.971"
        y1="120.854"
        x2="460.6"
        y2="144.413"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#98A1F2" />
        <stop offset="1" stopColor="#202EAE" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1401_11320"
        x1="221.407"
        y1="78.9862"
        x2="255.495"
        y2="134.783"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF9900" />
        <stop offset="1" stopColor="#FFCA39" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_1401_11320"
        x1="178.223"
        y1="116.155"
        x2="-10.6659"
        y2="116.155"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.635417" stopColor="#0819B9" />
        <stop offset="1" stopColor="#041578" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_1401_11320"
        x1="418.105"
        y1="17.0082"
        x2="354.239"
        y2="82.4705"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FBCFB7" />
        <stop offset="0.464391" stopColor="#FCAB7E" />
        <stop offset="1" stopColor="#FF5B00" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_1401_11320"
        x1="494.503"
        y1="59.6187"
        x2="441.242"
        y2="119.608"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFCA39" />
        <stop offset="0.9999" stopColor="#FB7D22" />
        <stop offset="1" stopColor="#FF9839" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_1401_11320"
        x1="369.26"
        y1="140.546"
        x2="336.797"
        y2="182.469"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFCA39" />
        <stop offset="0.9999" stopColor="#FB7D22" />
        <stop offset="1" stopColor="#FF9839" />
      </linearGradient>
    </defs>
  </svg>
);

// eslint-disable-next-line import/no-default-export
export default SVGCone;
