const SVGMap = () => (
  <svg
    width="609"
    height="365"
    viewBox="0 0 609 365"
    fill="none"
    style={{ width: '100%' }}
    xmlns="http://www.w3.org/2000/svg"
    role="img"
  >
    <title>Graphic illustration of world map</title>
    <path d="M20.0685 57.5859L3.82812 46.7994L41.3841 38.7095L20.0685 57.5859Z" fill="#D4D7F7" />
    <path
      d="M568.692 319.701C569.098 319.701 553.635 300.644 545.854 291.116L552.451 360.15C557.695 346.667 568.286 319.701 568.692 319.701Z"
      fill="#D4D7F7"
    />
    <path
      d="M593.558 293.811L589.498 287.339L599.141 291.654L606.753 293.811L608.784 298.665L602.186 306.216L599.141 314.845L595.588 315.923V308.912L590.513 304.058L594.066 301.362L593.558 293.811Z"
      fill="#D4D7F7"
    />
    <path
      d="M594.066 321.857L591.529 318.621L588.991 314.846L586.961 316.464L583.916 320.239L585.439 329.408L586.961 338.037L585.439 345.048L587.469 352.06L591.529 353.678L592.544 345.048L594.066 338.576L592.544 329.408L594.066 321.857Z"
      fill="#D4D7F7"
    />
    <path d="M32.7568 72.1475L21.084 58.6643L41.892 39.7878L32.7568 72.1475Z" fill="#D4D7F7" />
    <path d="M34.2793 73.2261L42.907 39.7878L67.7752 45.7204L34.2793 73.2261Z" fill="#D4D7F7" />
    <path d="M16.5176 117.99L31.2355 76.4619L51.536 142.799L16.5176 117.99Z" fill="#D4D7F7" />
    <path d="M69.8069 46.2607L33.7734 75.3844L98.7351 125.003L69.8069 46.2607Z" fill="#D4D7F7" />
    <path d="M52.5489 142.801L32.7559 77.0034L97.7175 126.082L52.5489 142.801Z" fill="#D4D7F7" />
    <path d="M78.9412 134.71L52.043 144.418L71.836 154.126L78.9412 134.71Z" fill="#D4D7F7" />
    <path
      d="M100.256 124.464L71.3281 45.7218L86.046 43.0251L122.079 70.5308L100.256 124.464Z"
      fill="#D4D7F7"
    />
    <path d="M87.0605 41.4052L110.914 30.0793L123.094 69.4502L87.0605 41.4052Z" fill="#D4D7F7" />
    <path d="M118.526 46.7985L112.943 30.0793L130.199 39.7872L118.526 46.7985Z" fill="#D4D7F7" />
    <path
      d="M137.303 21.4524C135.273 26.1266 131.213 35.6906 131.213 36.5536L146.438 30.621L137.303 21.4524Z"
      fill="#D4D7F7"
    />
    <path
      d="M119.541 50.0361C119.555 50.0438 119.571 50.0554 119.586 50.0708L135.781 62.4407L125.124 69.9912C123.628 63.6339 120.467 50.9275 119.586 50.0708L119.541 50.0361Z"
      fill="#D4D7F7"
    />
    <path
      d="M136.288 61.3609C133.751 58.6643 128.574 53.271 128.168 53.271L147.961 39.7878L136.288 61.3609Z"
      fill="#D4D7F7"
    />
    <path d="M147.963 44.6426L137.812 63.519L156.59 68.9123L147.963 44.6426Z" fill="#D4D7F7" />
    <path
      d="M163.189 34.3953C164.712 30.7998 167.859 23.393 168.265 22.5301L180.952 20.3728L180.445 37.0919L163.189 34.3953Z"
      fill="#D4D7F7"
    />
    <path
      d="M179.935 38.709C178.243 42.6641 174.859 50.6821 174.859 51.1136L168.262 37.0911L179.935 38.709Z"
      fill="#D4D7F7"
    />
    <path d="M182.982 37.0928V22.531L200.238 28.4636L182.982 37.0928Z" fill="#D4D7F7" />
    <path
      d="M253.525 9.58691C250.311 15.5195 243.781 27.6004 243.375 28.4634L258.093 30.0813L253.525 9.58691Z"
      fill="#D4D7F7"
    />
    <path
      d="M226.625 55.9685C230.347 50.2156 237.892 38.6021 238.298 38.1707L258.598 39.7886L257.583 59.2044L241.343 68.373L226.625 55.9685Z"
      fill="#D4D7F7"
    />
    <path
      d="M260.121 59.7436L261.136 39.7885L294.632 34.3953C290.234 50.2155 281.437 82.1797 281.437 83.474C281.437 84.7684 267.226 68.1931 260.121 59.7436Z"
      fill="#D4D7F7"
    />
    <path d="M301.231 3.11401L259.615 37.631L334.22 27.3837L301.231 3.11401Z" fill="#D4D7F7" />
    <path d="M53.5649 160.598L51.0273 147.654L70.3128 155.205L53.5649 160.598Z" fill="#D4D7F7" />
    <path
      d="M73.8671 155.745C68.6228 157.722 57.9312 161.785 57.1191 162.217L110.408 184.868L73.8671 155.745Z"
      fill="#D4D7F7"
    />
    <path d="M83.507 259.834L62.1914 233.407L104.315 185.946L83.507 259.834Z" fill="#D4D7F7" />
    <path d="M87.0605 258.216L106.346 185.946L131.214 225.317L87.0605 258.216Z" fill="#D4D7F7" />
    <path
      d="M133.244 223.699L110.406 186.485L117.004 184.867L144.917 215.609L133.244 223.699Z"
      fill="#D4D7F7"
    />
    <path
      d="M149.486 304.599L88.584 260.374L132.23 228.014L155.068 274.397L149.486 304.599Z"
      fill="#D4D7F7"
    />
    <path d="M157.098 271.161L134.768 226.396L175.369 235.565L157.098 271.161Z" fill="#D4D7F7" />
    <path d="M153.038 213.453L136.29 224.239L174.354 232.868L153.038 213.453Z" fill="#D4D7F7" />
    <path
      d="M101.273 311.07C97.0442 295.43 88.4841 263.933 88.0781 263.07L133.247 321.318L101.273 311.07Z"
      fill="#D4D7F7"
    />
    <path d="M148.47 306.755L136.289 319.699L93.6582 266.306L148.47 306.755Z" fill="#D4D7F7" />
    <path d="M114.972 341.273L103.807 314.846L132.735 324.015L114.972 341.273Z" fill="#D4D7F7" />
    <path d="M133.243 327.25L118.018 341.812L140.348 353.138L133.243 327.25Z" fill="#D4D7F7" />
    <path d="M251.495 77.0034L236.27 86.7113L269.765 95.8799L251.495 77.0034Z" fill="#D4D7F7" />
    <path
      d="M201.25 95.8787C211.4 93.3618 231.904 88.3281 232.716 88.3281L270.272 99.654L269.257 143.34L201.25 95.8787Z"
      fill="#D4D7F7"
    />
    <path d="M226.625 186.486L202.771 99.6548L266.718 145.498L226.625 186.486Z" fill="#D4D7F7" />
    <path d="M183.488 113.137L198.714 100.194L222.567 182.171L183.488 113.137Z" fill="#D4D7F7" />
    <path d="M187.041 172.462L184.504 120.147L223.075 188.642L187.041 172.462Z" fill="#D4D7F7" />
    <path d="M265.196 149.272L227.133 189.722L238.806 201.048L265.196 149.272Z" fill="#D4D7F7" />
    <path
      d="M271.288 141.182C270.07 142.045 271.796 114.216 272.811 100.194C284.032 108.26 306.297 124.094 307.759 124.465C307.822 124.446 307.847 124.445 307.829 124.463C307.819 124.474 307.796 124.474 307.759 124.465C305.962 125.006 272.759 140.14 271.288 141.182Z"
      fill="#D4D7F7"
    />
    <path d="M308.335 175.16L270.779 145.497L308.335 127.16V175.16Z" fill="#D4D7F7" />
    <path d="M269.258 147.654L242.359 199.969L307.321 176.778L269.258 147.654Z" fill="#D4D7F7" />
    <path
      d="M308.844 178.936L242.359 203.205C254.032 221.902 277.479 259.403 277.885 259.835C278.291 260.266 298.693 206.082 308.844 178.936Z"
      fill="#D4D7F7"
    />
    <path d="M274.839 259.834L241.344 204.823L237.791 243.654L274.839 259.834Z" fill="#D4D7F7" />
    <path d="M226.625 279.25L237.283 246.891L274.331 261.992L226.625 279.25Z" fill="#D4D7F7" />
    <path
      d="M280.932 257.678C291.082 231.611 311.382 179.26 311.382 178.397L338.281 184.33L280.932 257.678Z"
      fill="#D4D7F7"
    />
    <path
      d="M306.815 238.261C306.409 237.83 300.555 259.654 297.679 270.621L315.95 258.216C313.074 251.744 307.221 238.693 306.815 238.261Z"
      fill="#D4D7F7"
    />
    <path
      d="M283.977 84.5528L295.649 38.1707L311.382 71.0696L298.694 84.5528H283.977Z"
      fill="#D4D7F7"
    />
    <path d="M372.283 72.1367L338.279 29.5417L385.986 29.5422L372.283 72.1367Z" fill="#D4D7F7" />
    <path
      d="M325.592 85.6298L296.664 35.4724L334.728 29.5398L397.659 107.742L325.592 85.6298Z"
      fill="#D4D7F7"
    />
    <path
      d="M309.858 86.709L323.561 87.7876V168.147L314.933 128.777V114.215L298.186 95.3382L309.858 86.709Z"
      fill="#D4D7F7"
    />
    <path d="M326.102 168.688V88.3281L345.895 122.845L326.102 168.688Z" fill="#D4D7F7" />
    <path
      d="M375.329 152.509L348.43 122.846L329.652 89.4077L399.182 110.981L375.329 152.509Z"
      fill="#D4D7F7"
    />
    <path
      d="M346.398 128.238L328.635 169.226L341.83 170.844L353.503 153.586L346.398 128.238Z"
      fill="#D4D7F7"
    />
    <path
      d="M373.803 74.8435L388.521 30.0793L431.152 69.4502L400.701 107.203L373.803 74.8435Z"
      fill="#D4D7F7"
    />
    <path d="M481.906 113.137L378.881 153.047L404.764 107.204L481.906 113.137Z" fill="#D4D7F7" />
    <path
      d="M434.707 69.598L391.568 29.001L409.331 29.0013L473.785 67.568L434.707 69.598Z"
      fill="#D4D7F7"
    />
    <path
      d="M393.597 188.642C389.029 178.395 379.793 157.685 379.387 156.822L480.889 115.833L393.597 188.642Z"
      fill="#D4D7F7"
    />
    <path
      d="M449.423 204.283L428.615 194.575C424.893 187.564 417.449 173.326 417.449 172.463C417.449 171.6 448.577 147.654 464.14 135.789L457.543 198.351L449.423 204.283Z"
      fill="#D4D7F7"
    />
    <path d="M465.664 132.553L481.904 118.53L479.874 148.193L465.664 132.553Z" fill="#D4D7F7" />
    <path d="M472.263 62.9789L411.869 27.9226L465.666 29.5406L472.263 62.9789Z" fill="#D4D7F7" />
    <path
      d="M406.795 103.968C415.084 93.7206 431.968 73.1183 433.186 72.6868L474.802 70.5295L482.414 110.979L406.795 103.968Z"
      fill="#D4D7F7"
    />
    <path d="M478.859 75.9222L468.201 30.0793L503.727 75.3828L478.859 75.9222Z" fill="#D4D7F7" />
    <path d="M505.251 72.1466L471.248 29.5398L503.221 32.2364L505.251 72.1466Z" fill="#D4D7F7" />
    <path
      d="M506.265 49.4962C506.096 44.103 505.656 33.2086 505.25 32.7771L521.998 44.6423L506.265 49.4962Z"
      fill="#D4D7F7"
    />
    <path d="M503.222 78.082H478.861L499.669 110.442L503.222 78.082Z" fill="#D4D7F7" />
    <path
      d="M481.904 164.912L497.637 137.946L500.682 141.182L490.532 175.699L481.904 164.912Z"
      fill="#D4D7F7"
    />
    <path d="M462.621 187.563L466.174 142.26L477.846 162.754L462.621 187.563Z" fill="#D4D7F7" />
    <path
      d="M422.527 222.081V210.216L430.14 205.362L439.275 228.553L422.527 222.081Z"
      fill="#D4D7F7"
    />
    <path d="M441.811 225.316L451.961 211.833L453.991 225.316H441.811Z" fill="#D4D7F7" />
    <path d="M459.068 211.833L471.249 199.968V217.226L459.068 211.833Z" fill="#D4D7F7" />
    <path
      d="M474.294 232.328C478.692 228.732 487.591 221.433 487.997 221.002L490.027 239.878L474.294 232.328Z"
      fill="#D4D7F7"
    />
    <path
      d="M476.831 249.047C476.831 249.478 475.14 239.519 474.294 234.485L492.057 244.193L482.921 254.44C480.891 252.463 476.831 248.615 476.831 249.047Z"
      fill="#D4D7F7"
    />
    <path
      d="M508.805 256.598C507.79 256.598 501.193 236.103 500.178 235.564L512.866 231.249L528.598 238.8C522.339 244.732 509.617 256.598 508.805 256.598Z"
      fill="#D4D7F7"
    />
    <path d="M537.224 263.07L512.355 257.137L529.611 241.497L537.224 263.07Z" fill="#D4D7F7" />
    <path
      d="M465.664 309.452L486.98 286.8L500.682 342.351L477.337 329.946L465.664 309.452Z"
      fill="#D4D7F7"
    />
    <path
      d="M511.342 277.092C510.834 277.092 489.519 285.182 488.504 285.182L497.132 318.62L543.315 288.418C539.594 285.002 531.947 278.17 531.135 278.17C530.12 278.17 511.85 277.092 511.342 277.092Z"
      fill="#D4D7F7"
    />
    <path
      d="M502.714 342.89C502.206 342.89 498.146 321.317 497.639 320.777L530.12 334.261C521.153 337.137 503.12 342.89 502.714 342.89Z"
      fill="#D4D7F7"
    />
    <path
      d="M543.822 290.574C529.95 299.563 501.902 317.757 500.684 318.619L532.657 332.642L551.435 364.462L543.822 290.574Z"
      fill="#D4D7F7"
    />
    <path
      d="M544.33 286.8C545.853 282.306 548.898 273.209 548.898 272.778L568.691 315.385L544.33 286.8Z"
      fill="#D4D7F7"
    />
    <path
      d="M441.811 227.474L442.826 239.879L459.574 233.407L454.498 227.474H441.811Z"
      fill="#D4D7F7"
    />
    <path d="M39.8617 39.2493L0.783203 47.3392L19.0537 58.665L39.8617 39.2493Z" fill="#D4D7F7" />
    <circle opacity="0.8" cx="45.4449" cy="80.8214" r="20.8082" fill="#EAEBFB" />
    <circle opacity="0.8" cx="45.4457" cy="80.8219" r="15.3324" fill="#939CEB" />
    <circle opacity="0.8" cx="45.4444" cy="80.8209" r="9.85653" fill="#202DAC" />
    <circle opacity="0.8" cx="89.8998" cy="118.296" r="29.3588" fill="#EAEBFB" />
    <circle opacity="0.8" cx="89.9004" cy="118.296" r="21.6328" fill="#939CEB" />
    <circle opacity="0.8" cx="89.9009" cy="118.295" r="13.9068" fill="#202DAC" />
    <circle opacity="0.8" cx="101.19" cy="218.413" r="14.1291" fill="#EAEBFB" />
    <circle opacity="0.8" cx="101.19" cy="218.413" r="10.4109" fill="#939CEB" />
    <circle opacity="0.8" cx="101.189" cy="218.413" r="6.69273" fill="#202DAC" />
    <circle opacity="0.8" cx="119.258" cy="64.7033" r="14.1291" fill="#EAEBFB" />
    <circle opacity="0.8" cx="119.259" cy="64.7036" r="10.4109" fill="#939CEB" />
    <circle opacity="0.8" cx="119.259" cy="64.7032" r="6.69273" fill="#202DAC" />
    <circle opacity="0.8" cx="57.1174" cy="164.912" r="14.1291" fill="#EAEBFB" />
    <circle opacity="0.8" cx="57.1179" cy="164.912" r="10.4109" fill="#939CEB" />
    <circle opacity="0.8" cx="57.1185" cy="164.911" r="6.69273" fill="#202DAC" />
    <circle opacity="0.8" cx="168.348" cy="243.578" r="19.8788" fill="#EAEBFB" />
    <circle opacity="0.8" cx="168.349" cy="243.578" r="14.6475" fill="#939CEB" />
    <circle opacity="0.8" cx="168.348" cy="243.577" r="9.41627" fill="#202DAC" />
    <circle opacity="0.8" cx="251.069" cy="218.952" r="14.1291" fill="#EAEBFB" />
    <circle opacity="0.8" cx="251.069" cy="218.952" r="10.4109" fill="#939CEB" />
    <circle opacity="0.8" cx="251.07" cy="218.952" r="6.69273" fill="#202DAC" />
    <circle opacity="0.8" cx="263.248" cy="178.031" r="18.2695" fill="#EAEBFB" />
    <circle opacity="0.8" cx="263.249" cy="178.031" r="13.4618" fill="#939CEB" />
    <circle opacity="0.8" cx="263.248" cy="178.03" r="8.65399" fill="#202DAC" />
    <circle opacity="0.8" cx="397.658" cy="182.171" r="14.1291" fill="#EAEBFB" />
    <circle opacity="0.8" cx="397.659" cy="182.171" r="10.4109" fill="#939CEB" />
    <circle opacity="0.8" cx="397.66" cy="182.171" r="6.69273" fill="#202DAC" />
    <circle opacity="0.8" cx="399.18" cy="75.2787" r="14.1291" fill="#EAEBFB" />
    <circle opacity="0.8" cx="399.18" cy="75.2793" r="10.4109" fill="#939CEB" />
    <circle opacity="0.8" cx="399.181" cy="75.2787" r="6.69273" fill="#202DAC" />
    <circle opacity="0.8" cx="493.586" cy="290.469" r="14.1291" fill="#EAEBFB" />
    <circle opacity="0.8" cx="493.587" cy="290.47" r="10.4109" fill="#939CEB" />
    <circle opacity="0.8" cx="493.587" cy="290.469" r="6.69273" fill="#202DAC" />
    <circle opacity="0.8" cx="345.893" cy="90.7538" r="14.1291" fill="#EAEBFB" />
    <circle opacity="0.8" cx="345.893" cy="90.7544" r="10.4109" fill="#939CEB" />
    <circle opacity="0.8" cx="345.892" cy="90.754" r="6.69273" fill="#202DAC" />
    <circle opacity="0.8" cx="263.246" cy="27.9221" r="14.1291" fill="#EAEBFB" />
    <circle opacity="0.8" cx="263.247" cy="27.9224" r="10.4109" fill="#939CEB" />
    <circle opacity="0.8" cx="263.247" cy="27.922" r="6.69273" fill="#202DAC" />
    <circle opacity="0.8" cx="291.506" cy="59.0969" r="14.1291" fill="#EAEBFB" />
    <circle opacity="0.8" cx="291.507" cy="59.0972" r="10.4109" fill="#939CEB" />
    <circle opacity="0.8" cx="291.507" cy="59.0968" r="6.69273" fill="#202DAC" />
    <circle opacity="0.8" cx="329.652" cy="29.1661" r="28.4193" fill="#EAEBFB" />
    <circle opacity="0.8" cx="329.651" cy="29.1666" r="20.9405" fill="#939CEB" />
    <circle opacity="0.8" cx="329.653" cy="29.1656" r="13.4618" fill="#202DAC" />
    <circle opacity="0.8" cx="497.303" cy="90.7538" r="14.1291" fill="#EAEBFB" />
    <circle opacity="0.8" cx="497.303" cy="90.7544" r="10.4109" fill="#939CEB" />
    <circle opacity="0.8" cx="497.304" cy="90.754" r="6.69273" fill="#202DAC" />
    <circle opacity="0.8" cx="537.224" cy="327.25" r="24.4877" fill="#EAEBFB" />
    <circle opacity="0.8" cx="537.225" cy="327.25" r="18.0436" fill="#939CEB" />
    <circle opacity="0.8" cx="537.224" cy="327.249" r="11.5995" fill="#202DAC" />
    <circle opacity="0.8" cx="583.916" cy="307.19" r="14.1291" fill="#EAEBFB" />
    <circle opacity="0.8" cx="583.917" cy="307.19" r="10.4109" fill="#939CEB" />
    <circle opacity="0.8" cx="583.917" cy="307.19" r="6.69273" fill="#202DAC" />
  </svg>
);

// eslint-disable-next-line import/no-default-export
export default SVGMap;
